const allLanguages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' }
]

const translations = {
  'en': {
    'en': <span><span className="icon-style"><img className="flag" src="en.png" /></span><span className="options-style ">&nbsp;English&nbsp;</span></span>,
    'es': <span><span className="icon-style"><img className="flag" src="es.png" /></span><span className="options-style ">&nbsp;Español&nbsp;</span></span>,
    'fr': <span><span className="icon-style"><img className="flag" src="fr.png" /></span><span className="options-style ">&nbsp;Français&nbsp;</span></span>,

    'MAIN_TEXT': <div><div>An AI Team to implement Machine Learning with success</div></div>,

    'S2_TITLE': <b>Team AI Accéder</b>,
    'S2_TEXT1': <div><b>Team AI Accéder</b> is a group of Data Scientists, Software Engineers and Technology Business experts working together with <b>ModelFactory.io™</b> AI system. </div>,

    'S3_TEXT1': <div><b>Our Engine: ModelFactory.io™</b></div>,
    'S3_TEXT2': <div>Our <b>ModelFactory.io™</b> AI system enables Data Scientists to deploy all their machine learning models under one system rapidly and successfully.</div>,
    'S3_TEXT4': <div><b>Easily automate multiple models training, production and validation.</b></div>,
    'S3_TEXT5': <div><b>Control all your models and your team's work under one system.</b></div>,
    'S3_TEXT6': <div><b>Work either from our cloud or any cloud you need.</b></div>,

    'S4_TEXT1': <div><b>How it works ModelFactory.io™?</b></div>,
    'S4_TEXT2': <div><b>ModelFactory.io™</b> trains, scores, and validates multiple ML models quickly and automatically obtaining datasets and delivering predictions on buckets or databases of your choice.</div>,

    'S5_TEXT1': <div><b>Our Support: Data Science and Software Engineering</b></div>,
    'S5_TEXT2': <div><b>Accéder AI Team</b> combines over 15 years of experience in developing innovative technology, data science, and software engineering.</div>,
    'S5_TEXT3': <div>We provide two solutions depending on your company's needs: a service with our team's support or our SaaS version at AWS.</div>,
    'S5_TEXT4': <div><b>ModelFactory.io™</b></div>,
    'S5_TEXT5': <div>Service</div>,
    'S5_TEXT8': <div>SaaS</div>,
    'S5_TEXT6': <div><b>Certified & Available on</b></div>,
    'S5_TEXT7': <div>We have experience with leading companies from different industry sectors: Cosmetics, Financial Services, Retail, Manufacturing, Telecom, and Health. </div>,

    'S5.5_TEXT1': <div><b>Solving High-impact Use Cases</b></div>,
    'S5.5_TEXT2': <div>Our team has plenty of experience deploying machine learning models in different industries to generate significant business value solving high-impact use cases.</div>,
    'S5.5_TEXT3': <div>Financial Services</div>,
    'S5.5_TEXT4': <div>Retail & E-commerce</div>,
    'S5.5_TEXT5': <div>Industrial Operations</div>,
    'S5.5_TEXT6': <div>Telecom Services</div>,
    'S5.5_TEXT7': <div>Health Services</div>,

    'S9_TEXT1': <div>Who We Are?</div>,//I suggest changing this https://ell.stackexchange.com/questions/7037/what-is-the-correct-sentence-who-are-we-or-who-we-are 
    'S9_TEXT2': <div><b>ModelFactory.io™</b> is a technology developed by <b>Accéder</b>, a Montreal based company dedicated to technology innovation and working with technology entrepreneurs and best-in-class experts worldwide. We are passionate about working with groundbreaking technology to create something new and better for our clients. We have been working in the Data space for many years, and we are excited about machine learning and artificial intelligence to improve people's ways to work and quality of life.</div>,
    'S9_TEXT3': <div><b>ModelFactory.io™</b> design and features solve the challenges Data Scientists on their daily work. We developed this system by solving these challenges working with our clients' Data Scientists teams and their internal Business Units. Our seasoned data scientists and software engineers put together their expertise and knowledge to develop something simple but powerful. We provide the autonomy Data Scientists want while giving them the capacity to automate the tedious work and share their models and results with the business areas timely and efficiently.</div>,

    'S10_TEXT1': <div><div>We offer more than a tool.</div><div> We give the Best Support you can get! </div></div>,
    'S10_TEXT2': <div>Learn more about <b>ModelFactory.io™</b> Service in this link: <a href="https://www.acceder.ca/mfs" target="_blank">https://www.acceder.ca/mfs</a> </div>,

    'PRIVACY': <div><a href="https://dev.modelfactory.io/policy.html" target="_blank">Privacy Policy</a> </div>,
    'COOKIES': <div><a href="https://dev.modelfactory.io/cookies.html" target="_blank">Cookies Policy</a> </div>,
    'DATA': <div><a href="https://dev.modelfactory.io/data.html" target="_blank">Data Policy</a> </div>,

    'SCHEDULE': <div>Free trial</div>,
    'LEARN_MORE': <div>Learn More</div>,
    'GO_TO_AWS': <div><span style={{ color: 'orange' }}>Go to</span> AWS</div>,
    'CONTACT_US': <div>&nbsp;Contact Us&nbsp;</div>,
    'READY': <div>&nbsp;I’m Ready&nbsp;</div>,
    'REQUEST': <div>Download this Part One and receive subsequent parts when they come out.</div>,

    'ENGLISH': <div>English</div>,
    'SPANISH': <div>Español</div>,
    'FRENCH': <div>Français</div>,

    'SUCCESS': 'Thank you! A manager will contact you',
    'FAIL': 'An error occurred, please try again later',

    'DOWNLOAD': 'Download',
    'SEND': 'Send',
    'CLOSE': 'Close',
    'CANCEL': 'Cancel',
    'SCHEDULE_BTN': 'Schedule',
    'DATE': 'Date',
    'COUNTRY': 'Country',
    'EMAIL': 'Email',
    'NAME': 'Name',
    'REQUEST_BTN': 'Request',
    'PHONE': 'Phone Number',

    'LAST_NAME': 'Last Name',
    'COMPANY': 'Company',
    'JOB': 'Job Title',
    'TELL_US': 'Tell us about your interest',
    'INDUSTRY': 'Industry',
    'DEFAULT': 'Choose an option',

    'OFFER': 'Yes! Please email me newsand offers for ModelFactory.io products and services.',
    'PRIVACY_TERMS': 'You may opt out of these messages at any time by using the unsubscribe link provided in all ModelFactory.io email communications. ModelFactory.io is committed to protecting your privacy. You can find full details of how we use your information in our Privacy Policy.',
    'FREE_TRIAL_EXPLANATION': 'By submitting this form, you will receive an email confirmation giving you access to 30 days of Free Trial.'
  },
  'es': {
    'en': <span><span className="icon-style"><img className="flag" src="en.png" /></span><span className="options-style ">&nbsp;English&nbsp;</span></span>,
    'es': <span><span className="icon-style"><img className="flag" src="es.png" /></span><span className="options-style ">&nbsp;Español&nbsp;</span></span>,
    'fr': <span><span className="icon-style"><img className="flag" src="fr.png" /></span><span className="options-style ">&nbsp;Français&nbsp;</span></span>,

    'MAIN_TEXT': <div>Descubre como implementar exitosamente IA en tu negocio</div>,

    'S2_TITLE': <b>¡Tenemos el mejor equipo!</b>,
    'S2_TEXT1': <div><b>AI Team Accéder</b> cuenta con la experiencia y tecnología para darte un servicio acompañado, resolver casos de uso de alto impacto de tu empresa con modelos de IA y todo sin hacer grandes inversiones.</div>,

    'S3_TEXT1': <div><b>Nuestro motor ModelFactory.io™</b></div>,
    'S3_TEXT2': <div>Nuestro sistema de inteligencia artificial <b>ModelFactory.io ™</b> permite a los científicos de datos implementar todos sus modelos de machine learning en un solo sistema de manera rápida y exitosa.</div>,

    'S3_TEXT4': <div><b>Automatice fácilmente el entrenamiento, la producción y la validación de múltiples modelos.</b></div>,
    'S3_TEXT5': <div><b>Controle todos sus modelos y el trabajo de su equipo bajo un solo sistema.</b></div>,
    'S3_TEXT6': <div><b>Trabaje desde nuestra nube o desde cualquier nube que necesite.</b></div>,

    'S4_TEXT1': <div><b>¿Cómo funciona ModelFactory.io™?</b></div>,
    'S4_TEXT2': <div><b>ModelFactory.io™</b> entrena, pone en producción y valida múltiples modelos de machine learning de forma rápida y automática, obteniendo datasets y entregando predicciones en depósitos o bases de datos de su elección.</div>,
   

    'S5_TEXT1': <div><b>Nuestro Soporte: Ciencia de Datos e Ingeniería de Software</b></div>,
    'S5_TEXT2': <div>Nuestro <b>Equipo de IA Accéder</b>  combina más de 15 años de experiencia en el desarrollo de tecnología innovadora, ciencia de datos e ingeniería de software.</div>,
    'S5_TEXT3': <div>Brindamos dos soluciones en función de las necesidades de su empresa: un servicio con el soporte de nuestro equipo o nuestra versión SaaS en AWS.</div>,
    'S5_TEXT4': <div><b>ModelFactory.io™</b></div>,
    'S5_TEXT5': <div>Service</div>,
    'S5_TEXT8': <div>SaaS</div>,
    'S5_TEXT6': <div><b>Certified & Available on</b></div>,
    'S5_TEXT7': <div>Tenemos experiencia trabajando con compañias líderes en diferentes sectores de la industria: Cosmetica, Servicios Financieros, Venta, Manufactura, Telecomunicaciones y Salud.</div>,

    'S1.5_TEXT1': <div><b>GUÍA PRÁCTICA DE MACHINE LEARNING PARA NEGOCIOS</b></div>,
    'S1.5_TEXT2': <div>Descarga esta Guía Práctica de Machine Learning en los Negocios que hemos escrito con base a nuestra amplia experiencia en Inteligencia Artificial y con la retroalimentación de Chief Data Officers (CDOs) de empresas líderes en Latinoamérica.</div>,
    'S1.5_TEXT3': <div>En esta Primera Parte, compartimos varias mejores prácticas para implementar y usar modelos de machine learning en la resolución de casos de uso de alto impacto. También incluímos tips importantes que te ayudarán evitar errores comunes y costosos que otros han cometido.</div>,
    'S1.5_TEXT4': <div>¡Descárgala gratis ahora!</div>,

    'S5.5_TEXT1': <div><b>Resolviendo casos de uso de alto impacto</b></div>,
    'S5.5_TEXT2': <div>Nuestro equipo tiene una gran experiencia resolviendo casos de uso de alto impacto para empresas con modelos de machine learning en diferentes industrias.</div>,
    'S5.5_TEXT3': <div>Servicios Financieros</div>,
    'S5.5_TEXT4': <div>Retail & E-commerce</div>,
    'S5.5_TEXT5': <div>Operaciones Industriales</div>,
    'S5.5_TEXT6': <div>Servicios de Telecom</div>,
    'S5.5_TEXT7': <div>Servicios de Salud</div>,

    'S9_TEXT1': <div>¿Quiénes Somos?</div>,
    'S9_TEXT2': <div><b>ModelFactory.io™</b> es una tecnología desarrollada por Accéder, una empresa con sede en Montreal dedicada a la innovación tecnológica y que trabaja con empresarios tecnológicos y los mejores expertos de su clase en todo el mundo. Nos apasiona trabajar con tecnología innovadora para crear algo nuevo y mejor para nuestros clientes. Hemos estado trabajando en el mundo del Data durante muchos años y nos entusiasma el machine learning y la inteligencia artificial para mejorar la forma de trabajar y la calidad de vida de las personas.</div>,
    'S9_TEXT3': <div>El diseño y las características de <b>ModelFactory.io™</b> resuelven los desafíos de los científicos de datos en su trabajo diario. Desarrollamos este sistema resolviendo estos desafíos trabajando con los equipos de Data Science de nuestros clientes y sus Unidades de Negocio internas. Nuestros experimentados científicos de datos e ingenieros de software unieron su experiencia y conocimiento para desarrollar algo simple pero poderoso. Brindamos la autonomía que los científicos de datos desean al mismo tiempo que les brindamos la capacidad de automatizar el tedioso trabajo y compartir sus modelos y resultados con las áreas de negocio de manera oportuna y eficiente.</div>,

    'S10_TEXT1': <div>Ofrecemos más que una herramienta.<br />¡Le brindamos el Mejor Soporte!</div>,
    'S10_TEXT2': <div>Conoce más acerca de <b>ModelFactory.io™</b> Service en el siguiente link: <a href="https://www.acceder.ca/mfs" target="_blank">https://www.acceder.ca/mfs</a> </div>,

    'S11_TEXT1': <div>¡Tecnología a la medida!</div>,
    'S11_TEXT2': <div>Con nuestro ModelFactory.io™ automatizamos la producción de modelos de AI rápidamente.</div>,
    'S11_TEXT3': <div>Disponible en</div>,

    'S12_TEXT1': <div>¿Quiénes somos?</div>,
    'S12_TEXT2': <div>Con nuestro ModelFactory.io™ automatizamos la producción de modelos de AI rápidamente.</div>,
    'S12_TEXT3': <div>¡Disponible ahora para uso inmediato!</div>,

    'BTN1': 'Digital Solutions',
    'BTN2': 'Data Solutions',
    'BTN3': 'AI Solutions',

    'PRIVACY': <div><a href="https://dev.modelfactory.io/policy.html" target="_blank">Aviso de Privacidad</a> </div>,
    'COOKIES': <div><a href="https://dev.modelfactory.io/cookies.html" target="_blank">Política de Cookies</a> </div>,
    'DATA': <div><a href="https://dev.modelfactory.io/data.html" target="_blank">Política de Datos</a> </div>,

    'SCHEDULE': <div>POC Gratis</div>,
    'DOWNLOAD_ALT': <div>Descárgala</div>,
    'LEARN_MORE': <div>Conoce Más</div>,
    'GO_TO_AWS': <div><span style={{ color: 'orange' }}>Go to</span> AWS</div>,
    'CONTACT_US': <div>&nbsp;Contáctanos&nbsp;</div>,
    'READY': <div>&nbsp;Estoy Listo&nbsp;</div>,
    'REQUEST': <div>Descarga esta Primera Parte y recibe las partes subsecuentes cuando salgan. </div>,

    'ENGLISH': <div>English</div>,
    'SPANISH': <div>Español</div>,
    'FRENCH': <div>Français</div>,

    'SUCCESS': '¡Muchas gracias! Un ejecutivo se pondrá en contacto contigo',
    'FAIL': 'Se produjo un error, por favor, inténtelo de nuevo más tarde',

    'DOWNLOAD': 'Descargar',
    'SEND': 'Enviar',
    'CLOSE': 'Cerrar',
    'CANCEL': 'Cancelar',
    'SCHEDULE_BTN': 'Agendar',
    'DATE': 'Fecha',
    'COUNTRY': 'País',
    'EMAIL': 'Email',
    'NAME': 'Nombre',
    'REQUEST_BTN': 'Solicitar',
    'PHONE': 'Número de Teléfono',

    'LAST_NAME': 'Apellido',
    'COMPANY': 'Compañia',
    'JOB': 'Puesto',
    'TELL_US': 'Indicanos tu interés',
    'INDUSTRY': 'Industria',
    'DEFAULT': 'Selecciona una opción',

    'OFFER': 'Yes! Please email me newsand offers for ModelFactory.io products and services.',
    'PRIVACY_TERMS': 'You may opt out of these messages at any time by using the unsubscribe link provided in all ModelFactory.io email communications. ModelFactory.io is committed to protecting your privacy. You can find full details of how we use your information in our Privacy Policy.',
    'FREE_TRIAL_EXPLANATION': 'By submitting this form, you will receive an email confirmation giving you access to 30 days of Free Trial.'
  },
  'fr': {
    'en': <span><span className="icon-style"><img className="flag" src="en.png" /></span><span className="options-style ">&nbsp;English&nbsp;</span></span>,
    'es': <span><span className="icon-style"><img className="flag" src="es.png" /></span><span className="options-style ">&nbsp;Español&nbsp;</span></span>,
    'fr': <span><span className="icon-style"><img className="flag" src="fr.png" /></span><span className="options-style ">&nbsp;Français&nbsp;</span></span>,

    'MAIN_TEXT': <div>Une équipe d'IA pour mettre en œuvre avec succès des modèles d'apprentissage automatique</div>,

    'S2_TITLE': <b>Équipe IA Accéder</b>,
    'S2_TEXT1': <div><b>L'équipe AI Accéder</b> est un groupe de scientifiques des données, d'ingénieurs logiciels et d'experts en technologie d'entreprise, qui travaillent avec le système d'intelligence artificielle <b>ModelFactory.io ™</b>, en tant qu'équipe de Formule 1 pour maximiser vos données et résoudre des cas d'entreprises à fort impact.</div>,

    'S3_TEXT1': <div><b>Notre moteur: ModelFactory.io ™</b></div>,
    'S3_TEXT2': <div>Notre système d'IA <b>ModelFactory.io™</b> permet aux scientifique des données de déployer rapidement et avec succès tous leurs modèles d'apprentissage automatique sous un seul système.</div>,
    'S3_TEXT4': <div><b>Automatisez facilement la formation, la production et la validation de plusieurs modèles.</b></div>,
    'S3_TEXT5': <div><b>Contrôlez tous vos modèles et le travail de votre équipe sous un seul système.</b></div>,
    'S3_TEXT6': <div><b>Travaillez à partir de notre cloud ou de n'importe quel cloud dont vous avez besoin.</b></div>,

    'S4_TEXT1': <div><b>Comment fonctionne ModelFactory.io ™?</b></div>,
    'S4_TEXT2': <div><b>ModelFactory.io™</b> entraîne, score et valide plusieurs modèles ML rapidement et automatiquement en obtenant des ensembles de données et en fournissant des prédictions sur les buckets ou les bases de données de votre choix.</div>,

    'S5_TEXT1': <div><b>Notre support: Data Science et Génie logiciel</b></div>,
    'S5_TEXT2': <div><b>Accéder AI Team</b> combine plus de 15 ans d'expérience dans le développement de technologies innovantes, la science des données et l'ingénierie logicielle.</div>,
    'S5_TEXT3': <div>Nous proposons deux solutions en fonction des besoins de votre entreprise: un service avec le support de notre équipe ou notre version SaaS chez AWS.</div>,
    'S5_TEXT4': <div><b>ModelFactory.io™</b></div>,
    'S5_TEXT5': <div>Service</div>,
    'S5_TEXT8': <div>SaaS</div>,
    'S5_TEXT6': <div><b>Certified & Available on</b></div>,
    'S5_TEXT7': <div>Nous avons de l'expérience avec des entreprises leaders de différents secteurs industriels: cosmétiques, services financiers, vente au détail, fabrication, télécommunications et santé.</div>,

    'S5.5_TEXT1': <div><b>Résolution de cas d'utilisation à fort impact</b></div>,
    'S5.5_TEXT2': <div>Notre équipe possède une grande expérience dans le déploiement de modèles d'apprentissage automatique dans différents secteurs pour générer une valeur commerciale significative en résolvant des cas d'utilisation à fort impact.</div>,
    'S5.5_TEXT3': <div>Services financiers</div>,
    'S5.5_TEXT4': <div>Vendre au détail & Commerce électronique</div>,
    'S5.5_TEXT5': <div>Opérations industrielles</div>,
    'S5.5_TEXT6': <div>Services de télécommunications</div>,
    'S5.5_TEXT7': <div>Services de santé</div>,

    'S9_TEXT1': <div>Qui sommes-nous?</div>,
    'S9_TEXT2': <div><b>ModelFactory.io™</b> est une technologie développée par Accéder, une entreprise montréalaise dédiée à l'innovation technologique et travaillant avec des entrepreneurs technologiques et des experts de premier ordre dans le monde entier. Nous sommes passionnés par le travail et sommes fiers de notre technologie révolutionnaire qui permet de créer quelque chose de nouveau et de meilleur pour nos clients. Nous travaillons dans le domaine des données depuis de nombreuses années et nous sommes enthousiasmés par l'apprentissage automatique et l'intelligence artificielle pour améliorer les façons de travailler et la qualité de vie des gens.</div>,
    'S9_TEXT3': <div>La conception et les fonctionnalités de <b>ModelFactory.io ™</b> résolvent les défis des Data Scientists dans leur travail quotidien. Nous avons développé ce système en résolvant ces défis en travaillant avec les équipes de Data Scientists de nos clients et leurs unités d’affaires internes. Nos data scientists et ingénieurs logiciels chevronnés ont mis en commun leur expertise et leurs connaissances pour développer quelque chose de simple et puissant. Nous fournissons l'autonomie souhaitée par les Data Scientists tout en leur donnant la capacité d'automatiser le travail fastidieux et de partager leurs modèles et résultats avec les secteurs d'activité de manière rapide et efficace.</div>,

    'S10_TEXT1': <div>Nous offrons plus qu'un outil.<br />Nous offrons le meilleur soutien que vous puissiez obtenir!</div>,
    'S10_TEXT2': <div>En savoir plus sur le service <b>ModelFactory.io ™</b> dans ce lien:  <a href="https://www.acceder.ca/mfs" target="_blank">https://www.acceder.ca/mfs</a> </div>,

    'PRIVACY': <div><a href="https://dev.modelfactory.io/policy.html" target="_blank">Avis de confidentialité</a> </div>,
    'COOKIES': <div><a href="https://dev.modelfactory.io/cookies.html" target="_blank">Politique de cookies</a> </div>,
    'DATA': <div><a href="https://dev.modelfactory.io/data.html" target="_blank">Politique de données</a> </div>,

    'SCHEDULE': <div>Essai Gratuit</div>,
    'LEARN_MORE': <div>Apprendre encore plus</div>,
    'GO_TO_AWS': <div><span style={{ color: 'orange' }}>Go to</span> AWS</div>,
    'CONTACT_US': <div>&nbsp;Nous contacter&nbsp;</div>,
    'READY': <div>&nbsp;Je suis prêt&nbsp;</div>,
    'REQUEST': <div>Téléchargez cette première partie et recevez les parties suivantes dès leur sortie.</div>,

    'ENGLISH': <div>English</div>,
    'SPANISH': <div>Español</div>,
    'FRENCH': <div>Français</div>,

    'SUCCESS': 'Merci beaucoup! Un cadre vous contactera',
    'FAIL': "Une erreur s'est produite veuillez réessayer plus tard",

    'DOWNLOAD': 'Download',
    'SEND': 'Envoyer',
    'CLOSE': 'Fermer',
    'CANCEL': 'Annuler',
    'SCHEDULE_BTN': 'Agendar',
    'DATE': 'Fecha',
    'COUNTRY': 'Pays ',
    'EMAIL': 'E-mail ( Au Québec : Courriel)',
    'NAME': 'Prenom',
    'REQUEST_BTN': 'Demande',
    'PHONE': 'Numéro de téléphone',

    'LAST_NAME': 'Nom',
    'COMPANY': 'Entreprise',
    'JOB': 'Fonctio',
    'TELL_US': 'Faites-nous part de ce qui vous intéresse',
    'INDUSTRY': 'Industrie',
    'DEFAULT': 'Choisis une option',

    'OFFER': "Oui! Veuillez m'envoyer des nouvelles et des offres pour les produits et services ModelFactory.io",
    'PRIVACY_TERMS': "Vous pouvez désactiver ces messages à tout moment en utilisant le lien de désabonnement fourni dans toutes les communications par e-mail de ModelFactory.io. ModelFactory.io s'engage à protéger votre vie privée. Vous pouvez trouver tous les détails sur la façon dont nous utilisons vos informations dans notre politique de confidentialité.",
    'FREE_TRIAL_EXPLANATION': 'By submitting this form, you will receive an email confirmation giving you access to 30 days of Free Trial.'
  }
}

export const getTranslation = (text) => {
  return translations[language][text];
}

export const setLanguage = (value) => {
  language = localStorage.setItem("language", value);
  window.location.reload();
}

export const getLanguage = () => {
  if (localStorage.getItem("language"))
    return localStorage.getItem("language");
  else {
    var userLang = navigator.language || navigator.userLanguage; 
    userLang = userLang.split("-")[0];
    return userLang;
  }
}

var language = getLanguage();