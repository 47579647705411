import React, { useState, useEffect } from 'react'
import { getTranslation, setLanguage, getLanguage } from './translation.js'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import ReactPlayer from 'react-player'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Calendar from 'react-calendar'
import { isMobile } from 'react-device-detect';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios'

import logo from './img/logo.png';
import data1 from './img/data1.png';
import data2 from './img/data2.png';
import data3 from './img/data3.png';

import a1 from './img/a1.png';
import a2 from './img/a2.png';
import a3 from './img/a3.png';

import s31 from './img/s31.png';
import s32 from './img/s32.png';
import s33 from './img/s33.png';

import engine from './img/engine.png';
import engine1 from './img/engine1.png';
import engine2 from './img/engine2.png';

import serviceIMG from './img/service.png';
import aws from './img/aws.png';
import awsMk from './img/aws_mk.png';

import m1 from './img/m1.png';
import m2 from './img/m2.png';
import m3 from './img/m3.png';

import s1 from './img/s1.png';
import s2 from './img/s2.png';
import s3 from './img/s3.png';

import who from './img/who.png';
import who_es from './img/who_es.png';
import mf1 from './img/mf1.png';

import cup1 from './img/cup1.png';
import cup2 from './img/cup2.png';
import cup3 from './img/cup3.png';
import cup4 from './img/cup4.png';
import cup5 from './img/cup5.png';

import book from './img/book.jpg';

import './App.css';
import "animate.css/animate.min.css";
import 'react-calendar/dist/Calendar.css';

function App() {
  const [show, setShow] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [picker, setPicker] = useState(new Date());
  const [date, setDate] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [localLanguage, setLocalLanguaje] = useState(getLanguage());

  const handleClose = () => setShow(false);
  const handleShow = (value) => {
    setShow(true);
    setFormComplete(value);
  }

  const handleCloseScheduler = () => setShowScheduler(false);
  const handleShowScheduler = () => setShowScheduler(true);

  const handleCloseResult = () => setShowResult(false);
  const handleShowResult = () => setShowResult(true);

  const handleOnLanguageChange = (e) => setLanguage(e);

  useEffect(() => {
    setDate(picker.getTime())
    console.log(picker.getTime())

    /*var s4_1 = document.getElementById("s4_1")
    var s4_2 = document.getElementById("s4_2")
    var s4_3 = document.getElementById("s4_3")
    var s4_height = localStorage.getItem("language") == 'en' ? s4_3.offsetHeight : s4_2.offsetHeight
    s4_height += 20;

    s4_1.style.height = s4_height + "px";
    s4_2.style.height = s4_height + "px";
    s4_3.style.height = s4_height + "px";

    var s8_1 = document.getElementById("s8_1")
    var s8_2 = document.getElementById("s8_2")
    var s8_3 = document.getElementById("s8_3")
    var s8_height = localStorage.getItem("language") == 'en' ? s8_3.offsetHeight : s8_2.offsetHeight
    s8_height += 20;

    s8_1.style.height = s8_height + "px";
    s8_2.style.height = s8_height + "px";
    s8_3.style.height = s8_height + "px";*/

  });

  const downloadPDF = () => {
    window.open("https://modelfactory.io/cdn/gpPI.pdf");
  }

  const onSubmitRequest = (e) => {
    e.preventDefault();
    var form = document.getElementById('requestForm');
    var data = new FormData(form);
    //downloadPDF();
    setBtnDisabled(true)

    axios.post('https://modelfactory.io/core/api/mf/request', data, {})
      .then(res => {
        if (res.data == "DONE") {
          handleClose();
          setTitle('Message sent');
          setMessage(getTranslation('SUCCESS'));
          handleShowResult();
          setBtnDisabled(false)
        } else {
          handleCloseScheduler();
          setTitle('Error');
          setMessage(getTranslation('FAIL'));
          handleShowResult();
          setBtnDisabled(false)
        }

      })
  }

  const onSubmitScheduler = (e) => {
    e.preventDefault();
    var form = document.getElementById('schedulerForm');
    var data = new FormData(form);

    axios.post('https://dev.modelfactory.io/core/api/mf/scheduler', data, {})
      .then(res => {
        if (res.data == "DONE") {
          handleCloseScheduler();
          setTitle('Scheduled demo');
          setMessage('Perfect! we may contact you before the demo to get details ready');
          handleShowResult();
        } else {
          handleCloseScheduler();
          setTitle('Error');
          setMessage('An error occurred, please try again later');
          handleShowResult();
        }
      })
  }

  return (
    <div >
      <Navbar bg="light" fixed="top">
        <Navbar.Brand href="#home">
          <div onClick={() => window.open('https://www.acceder.ca/', '_blank')} >
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            accéder
          </div>
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Button className="mainBtn" variant="light" onClick={() => handleShow(true)}>{getTranslation('SCHEDULE')}</Button>
          <Nav>
            <NavDropdown title={getTranslation(localLanguage)}>
              <NavDropdown.Item onClick={() => handleOnLanguageChange('es')}>{getTranslation("SPANISH")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleOnLanguageChange('en')}>{getTranslation("ENGLISH")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleOnLanguageChange('fr')}>{getTranslation("FRENCH")}</NavDropdown.Item>
            </NavDropdown>
            {/*
              <Nav.Link eventKey={'es'}>{ getTranslation("SPANISH") }</Nav.Link>
              <Nav.Link eventKey={'en'}>{ getTranslation("ENGLISH") }</Nav.Link>
            */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* SECTION 1 */}
      <div style={{ height: '65px' }}></div>

      <Container className="mainBG mainSection">
        <Container>
          <Row>
            <Col className="mainLeft" xs={12} md={6}>
              <div className={localLanguage == "es" ? "backgroundLogoSimple" : "backgroundLogo"}>
                <div style={{ width: "100%" }}>
                  <div className="mainTitle"> {getTranslation('MAIN_TEXT')} </div>
                  <br />
                  {(!isMobile && localLanguage == "es") &&
                    <Button className={"mainBtn-orange"} variant="light" onClick={() => handleShow(false)}>{getTranslation('DOWNLOAD_ALT')}</Button>
                  }
                  {(!isMobile && localLanguage != "es") &&
                    <Button className={"mainBtn"} variant="light" onClick={() => handleShow(true)}>{getTranslation('SCHEDULE')}</Button>
                  }
                </div>
              </div>
            </Col>
            {localLanguage == "es" &&
              <Col className="moduleVertical" xs={12} md={6}>
                <div align="center"><img src={book} width="100%" /></div>
                {isMobile &&
                  <div>
                    <br/>
                    <Button className={"mainBtn-orange"} variant="light" onClick={() => handleShow(false)}>{getTranslation('DOWNLOAD_ALT')}</Button>
                  </div>
                }
              </Col>
            }
            {localLanguage != "es" &&
              <Col className="mainRight" xs={12} md={6}>
                <ReactPlayer
                  width="100%"
                  controls={true}
                  muted={true}
                  playing={true}
                  loop={true}
                  url='https://dev.modelfactory.io/media/intro.mp4' />
              </Col>
            }
          </Row>
        </Container>
      </Container>

      {/* SECTION 2 */}
      <div style={{ height: '70px' }}></div>

      <Container className="mainBG secondSection">
        <Container>
          <Row>
            <Col className="moduleContent" align="center">
              <div className="sectionDescAlt"> {getTranslation('S2_TITLE')} </div>
            </Col>
          </Row>
          <hr className="special_hr" />
          {!isMobile &&
          <div style={{ height: '50px' }}></div>
          }
          <Row>
            <Col className="moduleVertical" xs={12} md={12}>
              <div className="v-center">
                {getTranslation('S2_TEXT1')}
              </div>
            </Col>
          </Row>
          {!isMobile &&
            <div style={{ height: '80px' }}></div>
          }
          <Row>
            <Col className="moduleContent" align="center">

              {localLanguage == "en" &&
                <img src={a1} width="90%" />
              }
              {localLanguage == "es" &&
                <img src={a2} width="90%" />
              }
              {localLanguage == "fr" &&
                <img src={a3} width="90%" />
              }
            </Col>
          </Row>
          {localLanguage == "es" && 
            <Row>
              <Container>
                <div style={{ height: '20px' }}></div>
                <Row style={{ background: "#000", paddingBottom: "50px", color: "#fff" }}>
                  {!isMobile &&
                    <Col className="moduleContent" xs={12} md={1} align="center">
                      <div className="v-center">
                        &nbsp;
                      </div>
                      <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                    </Col>
                  }

                  <Col className="moduleContent" xs={6} md={2} align="center">
                    <img src={cup1} width="100%" />
                    <div className="">
                      <b>{getTranslation('S5.5_TEXT3')}</b>
                    </div>
                    <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                  </Col>
                  <Col className="moduleContent" xs={6} md={2} align="center">
                    <img src={cup2} width="100%" />
                    <div className="">
                      <b>{getTranslation('S5.5_TEXT4')}</b>
                    </div>
                    <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                  </Col>
                  <Col className="moduleContent" xs={6} md={2} align="center">
                    <img src={cup3} width="100%" />
                    <div className="">
                      <b>{getTranslation('S5.5_TEXT5')}</b>
                    </div>
                    <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                  </Col>
                  <Col className="moduleContent" xs={6} md={2} align="center">
                    <img src={cup4} width="100%" />
                    <div className="">
                      <b>{getTranslation('S5.5_TEXT6')}</b>
                    </div>
                    <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                  </Col>
                  <Col className="moduleContent" xs={6} md={2} align="center">
                    <img src={cup5} width="100%" />
                    <div className="">
                      <b>{getTranslation('S5.5_TEXT7')}</b>
                    </div>
                    <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                  </Col>

                  {!isMobile &&
                    <Col className="moduleContent" xs={12} md={1} align="center">
                      <div className="v-center">
                        &nbsp;
                      </div>
                      <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
                    </Col>
                  }
                </Row>
                <Row align="center">
                  <Col>
                    <br/>
                    <div align="center">
                      <Button className={"mainBtn-orange"} variant="light" onClick={() => handleShow(true)}>{getTranslation('SCHEDULE')}</Button>
                    </div>
                  </Col>
                </Row>
              </Container>
              
            </Row>
          }
        </Container>
      </Container>
      
      {localLanguage == "es" && 
        <div>
          <div style={{ height: '65px' }}></div>

          <Container>
            <Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S11_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '50px' }}></div>
          </Container>
          <Container className="mainBG mainSection">
            <Container>
              <Row>
                {/*<Col className="mainLeft" xs={12} md={12}>
                  <div className={localLanguage == "es" ? "backgroundLogoSimple" : "backgroundLogo"}>
                    <div style={{ width: "100%" }}>
                      <div className="mainTitle"> {getTranslation('S11_TEXT2')} </div>
                    </div>
                  </div>
                </Col>*/}
                <Col className="mainRight" xs={12} md={12}>
                  <ReactPlayer
                    width="100%"
                    controls={true}
                    muted={true}
                    playing={true}
                    loop={true}
                    url='https://dev.modelfactory.io/media/intro.mp4' />
                </Col>
              </Row>
            </Container>
          </Container>
          <br/>
          <br/>
          <Container className="mainBG secondSection">
            <Row>
              <Col className="mainLeft" xs={12} md={6}>
                <div className={"backgroundLogoSimple"}>
                  <div style={{ width: "100%" }}>
                    <div className="sectionDescAlt" style={{ width: "100%" }}> {getTranslation('S11_TEXT3')} </div>
                  </div>
                </div>
              </Col>
              <Col className="moduleVertical" xs={12} md={6}>
                {isMobile &&
                  <br/>
                }
                <div align="center"><img src={awsMk} width="90%" /></div>
              </Col>
            </Row>
            <Row>
              <Col className="moduleContent" align="center">
                <br/><br/>
                <div className="btn-holder">
                  <button onClick={() => window.open('https://aws.amazon.com/marketplace/pp/B08WBXHCML', '_blank')} type="button" className="awsBtn btn-small btn-light-small"><div>{getTranslation('GO_TO_AWS')} </div></button>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ height: '70px' }}></div>
          <Container>
            {/*<Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S11_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '10px' }}></div>
            */}
            <Row>
              <Col className="moduleContent" align="center">
                <img src={who_es} width="90%" />
              </Col>
            </Row>
            {isMobile &&
              <br/>
            }
            <Row>
              <Col className="moduleContent" align="center">
                <div className="background-box">Accéder somos un grupo de emprendedores y expertos en tecnología que brindamos acceso a nuestra experiencia y conocimiento para aumentar el valor de tu negocio resolviendo casos de uso de alto impacto y generando activos tecnológicos innovadores.</div>
              </Col>
            </Row>
            <div style={{ height: '30px' }}></div>
            <Row>
              <Col sm={4} className="moduleContent" align="center">
                <Button type="button" className="mainBtn btn btn-light" ><div>{getTranslation('BTN1')} </div></Button>
              </Col>
              <Col sm={4} className="moduleContent" align="center">
                <Button type="button" className="mainBtn btn btn-light" ><div>{getTranslation('BTN2')} </div></Button>
              </Col>
              <Col sm={4} className="moduleContent" align="center">
                <Button type="button" className="mainBtn btn btn-light" ><div>{getTranslation('BTN3')} </div></Button>
              </Col>
            </Row>
          </Container>
        </div>
      }

      {localLanguage != "es" && 
      <div>
        <div style={{ height: '70px' }}></div>

        {/* SECTION 3 */}

        <Container className="mainBG secondSection ">
          <div style={{ height: '50px' }}></div>
          <Container>
            <Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S3_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleVertical" xs={12} md={12}>
                <div className="v-center">
                  {getTranslation('S3_TEXT2')}
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <div className={isMobile ? 'hide' : ''} style={{ height: '70px' }}></div>
        <div className={isMobile ? 'hide' : ''} style={{ height: '70px' }}></div>
        <div style={{ height: '70px' }}></div>
        <Container className="mainBG secondSection ">
          <Container>
            <Row>
              <Col className="secondLeft" align="center" xs={12} md={3}>
                <div className="v-center fixed_height_div">
                  <div align="center" className="image-box"><img src={s31} height="130px" /></div>
                  <div className="dataDescSmaller">
                    {getTranslation('S3_TEXT4')}
                  </div>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col className="secondLeft" align="center" xs={12} md={3}>
                <div className="v-center fixed_height_div">
                  <div align="center" className="image-box"><img src={s32} height="130px" /></div>
                  <div className="dataDescSmaller">
                    {getTranslation('S3_TEXT5')}
                  </div>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col className="secondLeft" align="center" xs={12} md={3}>
                <div className="v-center fixed_height_div">
                  <div align="center" className="image-box"><img src={s33} height="130px" /></div>
                  <div className="dataDescSmaller">
                    {getTranslation('S3_TEXT6')}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ height: '60px' }}></div>
        </Container>

        <div className={isMobile ? 'hide' : ''} style={{ height: '50px' }}></div>
        <div className={isMobile ? 'hide' : ''} style={{ height: '70px' }}></div>
        <div style={{ height: '50px' }}></div>

        {/* SECTION 4 */}

        <Container className="mainBG secondSection">
          <Container>
            <Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S4_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleVertical" xs={12} md={12}>
                <div className="v-center">
                  {getTranslation('S4_TEXT2')}
                </div>
              </Col>
            </Row>
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleContent" align="center">
                {localLanguage == "en" &&
                  <img src={engine} width="90%" />
                }
                {localLanguage == "es" &&
                  <img src={engine1} width="90%" />
                }
                {localLanguage == "fr" &&
                  <img src={engine2} width="90%" />
                }
              </Col>
            </Row>
            <Row >
              <Col className="moduleContent" align="center">
                <Button type="button" className="mainBtn btn-small btn-light-small" onClick={() => handleShow(true)}><div>{getTranslation('SCHEDULE')} </div></Button>
              </Col>
            </Row>
          </Container>
        </Container>

        <div style={{ height: '50px' }}></div>
        <div style={{ height: '70px' }}></div>

        {/* SECTION 5 */}
        <div style={{ height: '70px' }}></div>

        <Container className="mainBG secondSection">
          <Container>
            <Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S5_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleVertical" xs={12} md={12}>
                <div className="v-center">
                  {getTranslation('S5_TEXT2')}
                </div>
              </Col>
            </Row>
            <div style={{ height: '50px' }}></div>
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleVertical" xs={12} md={12}>
                <div className="v-center">
                  {getTranslation('S5_TEXT3')}
                </div>
              </Col>
            </Row>
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleContent" xs={12} md={6} align="center">
                <div className="dataDescBiggest">
                  {getTranslation('S5_TEXT4')}
                  {getTranslation('S5_TEXT5')}
                </div>

                <img src={serviceIMG} width="60%" />
                <div style={{ height: '4px' }}></div>
                <div className="btn-holder">
                  <Button className="mainBtn btn-small btn-light-small" onClick={() => window.open('https://www.acceder.ca/mfs', '_blank')} ><div>{getTranslation('LEARN_MORE')} </div></Button>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>


              <Col className="moduleContent" xs={12} md={6} align="center">
                <div className="dataDescBiggest">
                  {getTranslation('S5_TEXT4')}
                  {getTranslation('S5_TEXT8')}
                </div>
                <br></br>
                {getTranslation('S5_TEXT6')}
                <div style={{ height: '20px' }}></div>
                <img src={aws} width="30%" />
                <div className="div-helper"></div>
                <div className="btn-holder">
                  <button onClick={() => window.open('https://aws.amazon.com/marketplace/pp/B08WBXHCML', '_blank')} type="button" className="awsBtn btn-small btn-light-small"><div>{getTranslation('GO_TO_AWS')} </div></button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <div style={{ height: '50px' }}></div>
        <div style={{ height: '70px' }}></div>

        {/* SECTION 5.5 */}

        <Container className="mainBG secondSection">
          <Container>
            <Row>
              <Col className="moduleContent" align="center">
                <div className="sectionDescAlt"> {getTranslation('S5.5_TEXT1')} </div>
              </Col>
            </Row>
            <hr className="special_hr" />
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleVertical" xs={12} md={12}>
                <div className="v-center">
                  {getTranslation('S5.5_TEXT2')}
                </div>
              </Col>
            </Row>
            <div style={{ height: '50px' }}></div>
            <Row>
              <Col className="moduleContent" xs={12} md={1} align="center">
                <div className="v-center">
                  &nbsp;
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>

              <Col className="moduleContent" xs={12} md={2} align="center">
                <img src={cup1} width="100%" />
                <div className="">
                  <b>{getTranslation('S5.5_TEXT3')}</b>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>
              <Col className="moduleContent" xs={12} md={2} align="center">
                <img src={cup2} width="100%" />
                <div className="">
                  <b>{getTranslation('S5.5_TEXT4')}</b>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>
              <Col className="moduleContent" xs={12} md={2} align="center">
                <img src={cup3} width="100%" />
                <div className="">
                  <b>{getTranslation('S5.5_TEXT5')}</b>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>
              <Col className="moduleContent" xs={12} md={2} align="center">
                <img src={cup4} width="100%" />
                <div className="">
                  <b>{getTranslation('S5.5_TEXT6')}</b>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>
              <Col className="moduleContent" xs={12} md={2} align="center">
                <img src={cup5} width="100%" />
                <div className="">
                  <b>{getTranslation('S5.5_TEXT7')}</b>
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>

              <Col className="moduleContent" xs={12} md={1} align="center">
                <div className="v-center">
                  &nbsp;
                </div>
                <div className={isMobile ? '' : 'hide'} style={{ height: '50px' }} ></div>
              </Col>
            </Row>
          </Container>
        </Container>

        <div style={{ height: '50px' }}></div>

        {/* SECTION 6 */}

        {/*
        <div style={{ height: '50px' }}></div>
        <Container className="mainBG ">
          <Container>
            <Row className="">
              <Col className="secondLeft blackSection" align="center">
                <div>
                  <div style={{ width: "100%" }}>

                    <div className="sectionText" style={{ color: "#fff" }}>{getTranslation('S5_TEXT7')} </div>
                  </div>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        */}

        <div style={{ height: '60px' }}></div>
        <Container className="mainBG secondSection">
          <Container>
            <Row>
              <Col className="secondLeft">
                <div className="sectionTitle" align="center">
                  <div>
                    <div> {getTranslation('S9_TEXT1')} </div>
                    <hr />
                  </div>
                  <div>
                    <img src={who} width="85%" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <div style={{ height: '32px' }}></div>

        <Container className="mainBG secondSection">
          <Container>
            <Row>
              <Col className="secondLeft">
                <div className="background-box">
                  <div className="dataDescBigger">{getTranslation('S9_TEXT2')}</div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className="mainBG secondSection">
          <Container>
            <br />
            <div align="center">
              <br />
              <Button className="mainBtn" variant="light" onClick={() => window.open('https://www.acceder.ca/home-english', '_blank')} >{getTranslation('LEARN_MORE')}</Button>
            </div>
          </Container>
        </Container>
      </div>
      }

      <div style={{ height: '50px' }}></div>
      <Container className="mainBG mainSection">
        <Container>
          <Row>
            <Col className="secondLeft" align="center">
              <div>
                <div style={{ width: "100%" }}>
                  <div className="sectionTitle" style={{ color: "#fff" }}>{getTranslation('S10_TEXT1')} </div>
                  <br />
                  <div className="sectionText" style={{ color: "#fff" }}>{getTranslation('S10_TEXT2')} </div>
                </div>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="mainBG secondSection">
        <Container align="center">
          <Row className="policy-style">

            <Col className="secondLeft">
              <div align="center" style={{ width: "100%" }}>
                <div className="sectionText">{getTranslation('DATA')} </div>
              </div>
            </Col>
            <Col className="secondLeft">
              <div align="center" style={{ width: "100%" }}>
                <div className="sectionText">{getTranslation('PRIVACY')} </div>
              </div>
            </Col>
            <Col className="secondLeft">
              <div align="center" style={{ width: "100%" }}>
                <div className="sectionText">{getTranslation('COOKIES')} </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="secondLeft">
              <div align="center">
                <div> Accéder™ and ModelFactory.io™ @Copyrights 2021 </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title align="center">
            {getTranslation("REQUEST")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="demo-style">
          <Form id="requestForm" onSubmit={onSubmitRequest}>

            <Container>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{getTranslation("NAME") + '*'}</Form.Label>
                    <Form.Control required type="text" name="name" placeholder={getTranslation("NAME")} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{getTranslation("EMAIL") + '*'}</Form.Label>
                    <Form.Control required type="email" name="email" placeholder={getTranslation("EMAIL")} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{getTranslation("PHONE")}</Form.Label>
                    <Form.Control required type="text" name="phone" placeholder={getTranslation("PHONE")} />
                  </Form.Group>
                </Col>
              </Row>
              {formComplete && 
                <div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>{getTranslation("COMPANY") + '*'}</Form.Label>
                        <Form.Control required type="text" name="company" placeholder={getTranslation("COMPANY")} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>{getTranslation("JOB") + '*'}</Form.Label>
                        <Form.Control required type="text" name="job" placeholder={getTranslation("JOB")} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Check type="checkbox" name="offer" label={getTranslation("OFFER")} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="privacy">{getTranslation('PRIVACY_TERMS')}</div>
                    </Col>
                  </Row>              
                  <Row>
                    <Col>
                      <div className="privacy">{getTranslation('FREE_TRIAL_EXPLANATION')}</div>
                    </Col>
                  </Row>              
                </div>
              }
            </Container>

            <Modal.Footer className="request-modal">
              <div align="center">
                <Button disabled={btnDisabled} type="submit" variant="light" className="mainBtn-orange">
                  {formComplete ? getTranslation("SEND") : getTranslation("DOWNLOAD")}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>

      <Modal show={showResult} onHide={handleCloseResult}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseResult}>
            {getTranslation("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default App;
